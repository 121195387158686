import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button, Icon, message, Skeleton } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import CustomerForm from '~/containers/DrawerSocietario/Forms/Customer/Index';
import * as customersActions from './actions';
import * as refundsActions from '~containers/DrawerRefunds/actions';
import { handleContractFiles } from './methods/handle-contract-files';
import { handlePartnersSets } from './methods/handle-partners-sets';
import { useAppContext } from '~/AppContext';

const Customer = props => {
    const [prepareData, setPrepareData] = useState({});
    const [contractFiles, setContractFiles] = useState([]);
    const [partnersSets, setPartnersSets] = useState([]);
    const [isDrawerFullyVisible, setIsDrawerFullyVisible] = useState(false);
    const { setOnboardingTaskAsDone } = useAppContext();

    const { 
        drawer,
        form,
        openDrawerRefunds,
        createCustomer,
        onClose,
        saveCustomer,
        getCustomerById,
    } = props;

    useEffect(() => {
        if (drawer.show && drawer.id) {
            getCustomerById(drawer.id);
        }
    }, [drawer.show, drawer.id, getCustomerById]);

    const data = get(drawer, 'data', {});

    useEffect(() => {
        if (data.id) {
            setContractFiles([
                ...data.contract_files,
                ...data.rescission_files,
            ]);
            setPartnersSets(data.partners_sets.map((partnersSet) => ({
                ...partnersSet,
                formatted_change_date: moment(partnersSet.change_date).format('DD/MM/YYYY')
            })));
        }
    }, [data]);

    const onCreate = () => {
        form.validateFieldsAndScroll((err, values) => {

            if (err) {
                return;
            }

            const formData = new FormData();

            handleContractFiles(contractFiles, formData);
            handlePartnersSets(partnersSets, formData);

            const fields = {...values, ...prepareData};
            fields.registered_at = moment(fields.registered_at).format('YYYY-MM-DD'); 
            fields.resp_compet_at = fields.resp_compet_at ? moment(fields.resp_compet_at).format('YYYY-MM-DD') : null;
            fields.resp_due_at = fields.resp_due_at ? moment(fields.resp_due_at).format('YYYY-MM-DD') : null;
            fields.conciliation = fields.conciliation ? 1 : 0;
            fields.show_in_federal = fields.show_in_federal ? 1 : 0;
            fields.show_in_state = fields.show_in_state ? 1 : 0;
            fields.show_in_municipal = fields.show_in_municipal ? 1 : 0;
            fields.show_in_fgts = fields.show_in_fgts ? 1 : 0;

            Object.keys(fields).forEach((key) => {
                if (key === 'honorary_history') {
                    const honoraryHistory = fields[key];
                    
                    honoraryHistory.forEach((item) => {
                        formData.append('honorary_history[]', JSON.stringify(item));
                    })
                } else if (key === 'honorary_history_deleted') {
                    const honoraryHistoryDeleted = fields[key];
                    
                    honoraryHistoryDeleted.forEach((item) => {
                        formData.append('honorary_history_deleted[]', item);
                    })
                } else if (fields[key] !== null && fields[key] !== undefined) {
                    formData.append(key, fields[key]);
                }
            });

            createCustomer(formData)
            .then(response => {
                form.resetFields();
                setPrepareData({});
                setContractFiles([]);
                setPartnersSets([]);
                onClose();

                // Update the onboardingData
                setOnboardingTaskAsDone('m3n4o5');
            })
            .catch(data => console.log(data));
        });
        
    }

    const onSave = () => {
        form.validateFieldsAndScroll((err, values) => {

            if (err) {
                return;
            }

            const formData = new FormData();

            handleContractFiles(contractFiles, formData);
            handlePartnersSets(partnersSets, formData);

            const fields = {...values, ...prepareData};
            fields.registered_at = moment(fields.registered_at).format('YYYY-MM-DD');
            fields.resp_compet_at = fields.resp_compet_at ? moment(fields.resp_compet_at).format('YYYY-MM-DD') : null;
            fields.resp_due_at = fields.resp_due_at ? moment(fields.resp_due_at).format('YYYY-MM-DD') : null;
            fields.conciliation = fields.conciliation ? 1 : 0;
            fields.show_in_federal = fields.show_in_federal ? 1 : 0;
            fields.show_in_state = fields.show_in_state ? 1 : 0;
            fields.show_in_municipal = fields.show_in_municipal ? 1 : 0;
            fields.show_in_fgts = fields.show_in_fgts ? 1 : 0;

            Object.keys(fields).forEach((key) => {
                if (key === 'honorary_history') {
                    const honoraryHistory = fields[key];
                    
                    honoraryHistory.forEach((item) => {
                        formData.append('honorary_history[]', JSON.stringify(item));
                    })
                } else if (key === 'honorary_history_deleted') {
                    const honoraryHistoryDeleted = fields[key];
                    
                    honoraryHistoryDeleted.forEach((item) => {
                        formData.append('honorary_history_deleted[]', item);
                    })
                } else if (fields[key] !== null && fields[key] !== undefined) {
                    formData.append(key, fields[key]);
                }
            });

            saveCustomer(get(data, 'id', false), formData)
            .then(response => {
                form.resetFields();
                setPrepareData({});
                setContractFiles([]);
                setPartnersSets([]);
                onClose();
            })
            .catch(data => {
                if(data.error){
                    if(data.error.response.status === 422 && data.error.response.data.email !== null){
                        console.log(data.error.response);
                        // context.setState({
                        //     email: {
                        //         validateStatus: 'error',
                        //         help: data.error.response.data.email[0]
                        //     }
                        // });
                    }
                }
            });
        });
    }

    return (
        <div>
            <Drawer
                title={get(drawer, 'id', false) ? get(data, 'name') : 'Cadastrar Cliente'}
                width={900}
                placement="right"
                onClose={() => {
                    setContractFiles([]);
                    setPartnersSets([]);
                    onClose();
                }}
                maskClosable={false}
                visible={drawer.show}
                destroyOnClose={true}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
                afterVisibleChange={(isVisible) => setIsDrawerFullyVisible(isVisible)}
            >
            {drawer.loading ? (
                <Skeleton active />
            ) : (
                <CustomerForm 
                    {...props} 
                    setPrepareData={(newData) => setPrepareData({...prepareData, ...newData})}
                    data={{
                        ...data,
                        contract_files: contractFiles,
                        partners_sets: partnersSets,
                        customer: data
                    }}
                    updateContractFilesState={setContractFiles}
                    isDrawerFullyVisible={isDrawerFullyVisible}
                    setPartnersSets={setPartnersSets}
                />
            )}
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button style={{
                    marginRight: 8,
                }}
                onClick={onClose}
                >
                Cancelar
                </Button>
                {get(data, 'id') && (
                <Button 
                    style={{ marginRight: 8}} 
                    type="info"
                    onClick={() => {
                        openDrawerRefunds({dash_task_id: get(data, 'id'), refund: {customer_id: get(data, 'customer_id')}})
                        }}>
                    <Icon type="plus" /> Comissão
                </Button>
                )}
                {(get(data, 'id', false))?
                <Button onClick={onSave} type="primary">Salvar</Button>
                :
                <Button onClick={onCreate} type="primary">Cadastrar</Button>
                }
            </div>
            </Drawer>
        </div>
        );

}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.customer.drawer,
});

const mapDispatchProps = dispatch => ({
    createCustomer: (data) => dispatch(customersActions.registerCustomer(data)),
    saveCustomer: (id, data) => dispatch(customersActions.saveCustomer(id, data)),
    getCustomerById: (id) => dispatch(customersActions.getCustomerById(id)),
    onClose: () => dispatch(customersActions.closeDrawerCustomer()),
    openDrawerRefunds: (payload) => dispatch(refundsActions.show(payload)),
});
  
export default connect(mapStateToProps, mapDispatchProps)(Form.create()(Customer));