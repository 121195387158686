import React, { useState } from 'react';
import { Form, Col, Row, Input, InputNumber, Select, Divider, Switch } from 'antd';
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';
import { OrgNames } from '~/components/SelectWithAdd';

const { Option } = Select;

const Opening = (props) => {
  const [defaultState] = useState({
    billing: 0.00,
    nfs_input: 0,
    nfs_output: 0,
    accounting_postings: 0,
    conciliation: false,
    n_staffs: 0,
    type_staff: null,
    first_honorary: 0.00,
    honorary: 0.00,
    first_honorary_c_payment: false,
    honorary_c_payments: false,
    paid_off: false,
    pendency: 0,
    org_name: null,
    org_number: null,
    org_password: null
  });

  const { getFieldDecorator } = props.form;
  const data = {
    ...defaultState,
    ...props.data
  };

  return (
    <div>
      <Row gutter={16} className="mb-20">
        <Divider orientation="left">Conselho</Divider>
        <Col span={8}>
          <Form.Item label="Nome do Conselho">
            {getFieldDecorator('org_name', {
              initialValue: isNaN(parseInt(data.org_name)) ? '' : parseInt(data.org_name) || data.org_name,
            })(
              <OrgNames placeholder="Nome do conselho" />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Número do Conselho">
            {getFieldDecorator('org_number', {
              initialValue: data.org_number,
            })(
              <Input placeholder="Número do conselho" />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Senha">
            {getFieldDecorator('org_password', {
              initialValue: data.org_password,
            })(
              <Input placeholder="Senha do conselho" />
            )}
          </Form.Item>
        </Col>
        <Divider orientation="left">Departamento Fiscal</Divider>
        <Col span={8}>
          <Form.Item label={<span>Faturamento <small>(mensal)</small></span>}>
            {getFieldDecorator('billing', {
              initialValue: data.billing,
              normalize: (value) => currency2Float(value)
            })(
              <InputCurrency />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<span>NFs Entrada <small>(mensal)</small></span>}>
            {getFieldDecorator('nfs_input', {
              initialValue: data.nfs_input,
            })(
              <InputNumber style={{ width: `100%` }} />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<span>NFs Saída <small>(mensal)</small></span>}>
            {getFieldDecorator('nfs_output', {
              initialValue: data.nfs_output,
            })(
              <InputNumber style={{ width: `100%` }} />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} className="mb-20">
        <Divider orientation="left">Departamento Contábil</Divider>
        <Col span={8}>
          <Form.Item label={<span>Lanç. Contábeis <small>(mensal)</small></span>}>
            {getFieldDecorator('accounting_postings', {
              initialValue: data.accounting_postings,
            })(
              <InputNumber style={{ width: `100%` }} />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Conciliação">
            {getFieldDecorator('conciliation', {
              valuePropName: 'checked',
              initialValue: data.conciliation
            })(
              <Switch />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} className="mb-20">
        <Divider orientation="left">Departamento Pessoal</Divider>
        <Col span={12}>
          <Form.Item label="Funcionários">
            {getFieldDecorator('n_staffs', {
              initialValue: data.n_staffs,
            })(
              <InputNumber style={{ width: `100%` }} />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tipo">
            {getFieldDecorator('type_staff', data.id !== null ? { initialValue: data.type_staff } : {})(
              <Select placeholder="Selecione um tipo de funcionário">
                <Option value="Honorista">Honorista</Option>
                <Option value="Mensalista">Mensalista</Option>
                <Option value="Comissionista">Comissionista</Option>
                <Option value="Comissionista Puro">Comissionista Puro</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Opening;