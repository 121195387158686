import { createAsyncActions } from '~/utils/store';

export const UPDATE_PARTNER = createAsyncActions('@@DRAWER_CUSTOMER/UPDATE_PARTNER');

export const loadCustomers = ({ ...params }) => ({
  type: "LOAD_CUSTOMERS",
  payload: {
    request: {
      method: "GET",
      url: "/customers",
      ...params,
    },
  },
});

export const registerCustomer = (data) => ({
    type: 'REGISTER_CUSTOMER',
    payload: {
      request:{
        method: 'post',
        url:'/customer',
        data
      }
    }
});

export const saveCustomer = (id, data) => ({
    type: 'SAVE_CUSTOMER',
    payload: {
        request:{
        method: 'post',
        url:`/customer/${id}`,
        data
        }
    }
});

export const showDrawerCustomer = (data = {}, openedFrom = 'Customers') => ({
  type: '@@CUSTOMER/SHOW',
  payload: {
    data,
    openedFrom
  }
});

export const closeDrawerCustomer = () => ({
    type: '@@CUSTOMER/CLOSE',
    payload: null
});

export const checkAccess = ({ customerId, email }) => ({
  type: "CHECK_CUSTOMER_ACCESS",
  payload: {
    request: {
      method: "GET",
      url: `/customer/${customerId}/check-access?email=${email}`,
    },
  },
});

export const updateAppUser = ({ appUserId, customerId, name, email }) => ({
  type: "UPDATE_APP_USER",
  payload: {
    request: {
      method: "PATCH",
      url: `/customer/${customerId}/app-user/${appUserId}`,
      data: {
        name,
        email
      },
      appUserId,
      customerId,
    },
  },
});

export const changeAppUserPassword = ({ id, password }) => ({
  type: "CHANGE_APP_USER_PASSWORD",
  payload: {
    request: {
      method: "PATCH",
      url: `/customer/${id}/change-password`,
      data: {
        password
      }
    }
  }
})

export const deleteAppUser = ({ customerId, appUserId }) => ({
  type: "DELETE_APP_USER",
  payload: {
    request: {
      method: "DELETE",
      url: `/customer/${customerId}/app-user/${appUserId}`
    },
    customerId,
    appUserId
  }
});

export const inviteAppUser = ({ customerId, email }) => ({
  type: "INVITE_APP_USER",
  payload: {
    request: {
      method: "POST",
      url: `/customer/${customerId}/app-user/invite`,
      data: {
        email,
      },
    },
    customerId
  }
})

export const resendInvite = ({ customerId, appUserId }) => ({
  type: "@@APP_USER/RESEND_INVITE",
  payload: {
    request: {
      method: "POST",
      url: `/customer/${customerId}/app-user/${appUserId}/resend-invite`,
    },
    customerId,
    appUserId,
  },
});

export const updatePartner = (data) => ({
  type: UPDATE_PARTNER.INIT,
  payload: {
    request: {
      method: 'PATCH',
      url: `/customer/partner/${data.id}`,
      data
    }
  }
});

export const batch = (data) => ({
  type: 'BATCH_CUSTOMERS',
  payload: {
    request: {
      method: 'POST',
      url: '/customers/batch',
      data
    }
  }
});

export const getCustomerById = (id) => ({
  type: '@@CUSTOMER/GET_BY_ID',
  payload: {
    request: {
      method: 'GET',
      url: `/customer/${id}`,
      showLoading: false
    },
  },
});
