import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DrawerBulk from '../_domains/tasks/drawerBulk';

import Layout from '../components/UI/Layout/Layout';
import List from '../components/RegisterTasks/List';
import Form from '../components/RegisterTasks/Form';
import Customers from '../components/RegisterTasks/Customers';
import { loadCustomers } from '~/_domains/customers/drawer/actions';
import { loadTasks } from '~/components/RegisterTasks/actions';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';

export const ScreensRegisterTasks = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [task, setTask] = useState(null);
  const [modal, setModal] = useState({
    isVisible: false,
    data: null,
  });

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await Promise.all([
        dispatch(loadTasks({ showLoading: false })),
        dispatch(loadCustomers({ showLoading: false })),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showModal(data = null) {
    setModal({
      isVisible: true,
      data,
    });
  }

  function hideModal() {
    setModal({
      isVisible: false,
      data: null,
    });
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <List
          isModalVisible={modal.isVisible}
          openCustomers={(task) => setTask(task)}
          isCustomersModalVisible={task !== null}
          showModal={showModal}
        />
        <Form visible={modal.isVisible} onClose={hideModal} data={modal.data} />
        <Customers
          visible={task !== null}
          task={task}
          onClose={() => setTask(null)}
        />
        {/* Render DrawerBulk with autoOpen when route is "/tarefas/start" */}
        <DrawerBulk autoOpen={location.pathname === '/tarefas/start'} />
      </Layout>
    </div>
  );
};
