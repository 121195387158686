import React from "react";

import Layout from "../../components/UI/Layout/Layout";
import CustomersList from "./list";
import CustomerInactivate from "../../components/Customers/CustomerInactivate";
import CustomerForm from "./drawer";
import RefundsForm from "~/containers/DrawerRefunds";

export const ScreensCustomers = (props) => {
  return (
    <div>
      <Layout {...props}>
        <CustomersList />
        <CustomerForm />
        <CustomerInactivate />
        <RefundsForm />
      </Layout>
    </div>
  );
};
