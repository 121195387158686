import React from 'react';
import styled from 'styled-components';
import { FaTools } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
`;

const Icon = styled(FaTools)`
  font-size: 4rem;
  color: #ff6f61;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const MaintenancePage = () => {
  return (
    <Container>
      <Icon />
      <Title>Página em manutenção</Title>
      <Message>Estamos trabalhando para melhorar sua experiência. Por favor, volte mais tarde.</Message>
    </Container>
  );
};

export default MaintenancePage;
