import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Switch, Icon,Button, Upload, Col, Row, Input, Select } from 'antd';
import { compare } from 'natural-orderby';
import _ from "lodash";
import { getSubdomain } from '~/helpers/getSubdomain';
import { AccountStorage } from '~/storages/account';


const getMonthName = (value) => {
    
    switch(value){
        case 1: return 'Janeiro';
        case 2: return 'Fevereiro';
        case 3: return 'Março';
        case 4: return 'Abril';
        case 5: return 'Maio';
        case 6: return 'Junho';
        case 7: return 'julho';
        case 8: return 'Agosto';
        case 9: return 'Setembro';
        case 10: return 'Outubro';
        case 11: return 'Novembro';
        case 12: return 'Dezembro';
        default: console.log(`Month Name: ${value} is not valid`);
    }
  }


const { Option } = Select;

const years = (back) => {
    const currentyear = new Date().getFullYear();
    return Array.from({length: back}, (v, i) => currentyear - back + i + 1).sort(function(a, b){return b-a});
}

class ConventionsForm extends React.Component {
    
    state = {
        default: {
            id: null,
            customers_id: [],
            owner_name: null,            
            date: null,            
            files: [],            
        },
        activeForm:{},  
        customers_id: [],
        files: [],
        removed_files:[],
        temp_customer_id: null
    };

    componentWillReceiveProps(nextProps) {          
        if (nextProps.data != null && this.state.customers_id.length == 0) {            
           this.setState({customers_id: [ ...this.state.customers_id, ...nextProps.data.customers_id]})        
        }
    }

    addCustomer = () => {
        const { temp_customer_id } = this.state;        
        let customers = this.state.customers_id || []
        customers.push({id: temp_customer_id, executed: false})        
                
        this.setState({customers_id: customers})
        this.setState({temp_customer_id: null})
    }
    rmCustomer = (id) => {        
        let customers = this.state.customers_id.filter(item => item.id != id)        
        
        this.setState({customers_id: customers})
    }

    changeCustomer = (id, val) => {
        let customers = this.state.customers_id.map((item) => {
            if (item.id == id) {
                item.executed = val
            }
            return item
        });
        
        this.setState({customers_id: customers})
    }

    onCreate = () => {
        const { form } = this.props;        
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            values.customers =  this.state.customers_id;
            values.files_id =  this.state.files;
            
            this.props.registerConvention(values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
                context.setState({customers_id: [], files: []});
            });
        });
        
    }

    onSave = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            values.customers =  this.state.customers_id;        
            values.files_id = this.state.files;
            values.removed_files =  this.state.removed_files;

            this.props.saveConvention(this.props.data.id, values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
                context.setState({customers_id: [], files: [], removed_files:[]});
            });
        });
        
    }

    onDone = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            values.customers =  this.state.customers_id;        
            values.files_id =  this.state.files;
            values.removed_files =  this.state.removed_files;

            this.props.saveConvention(this.props.data.id, {...values, done: true})
            .then(response => {
                form.resetFields();
                context.props.onClose();
                context.setState({customers_id: [], files: [], removed_files:[]});
            });
        });
        
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { customers } = this.props;        
        const context = this

        const data = {
            ...this.state.default,            
            ...this.props.data,            
            ...this.state.activeForm
        }

        console.log(data);

        const customersLength = this.state.customers_id.length;
        const customersDoneLength = this.state.customers_id.filter(row => row.executed === true).length;

        const dataFiles = {          
            action: process.env.REACT_APP_API+'/auth/conventions/upload',
            headers: {
                Authorization: `Bearer ${AccountStorage.get().token}`,
                'X-Tenant': getSubdomain()
            }, 
            onChange({ file, fileList }) {
                if (file.status !== 'uploading') {
                    console.log(file, fileList)
                    let files = fileList.map(item => {
                        if (item.response != undefined) {
                            return item.response.id
                        }
                        
                        return item.id                                        
                    })
                    
                    context.setState({files: files})
                }
                if(file.status === 'removed') {
                    context.setState({removed_files: [...context.state.removed_files, file.id]});
                }
            },            
            defaultFileList: data.files_id || [],
          };

        return (
        <div>
            <Drawer
                title={(data.id)? 'Editar Convenção Coletiva': 'Cadastrar Convenção Coletiva'}
                width={600}
                placement="right"
                onClose={() => {
                    this.props.form.resetFields();
                    this.props.onClose();
                    this.setState({activeForm: {}, customers_id: []});
                }}
                maskClosable={false}
                visible={this.props.visible}
                destroyOnClose={true}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <div className="scrollable-container" style={{ paddingBottom: '50px' }}>
                <Form layout="vertical" >
                <Row gutter={16}>                       
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Sindicado">
                            {getFieldDecorator('owner_name', {
                                initialValue: data.owner_name,
                                rules: [{ required: true, message: 'Informe o nome' }],
                            })(<Input placeholder="Informe o nome" />)}
                            </Form.Item>
                        </Col>           
                    </Row>
                    {(customers && this.state.customers_id.length > 0) && (      
                    <Row  style={{ paddingBottom: '16px' }}>
                        { this.state.customers_id.map(row => (
                            <Row key={row.id} align="middle" type="flex" style={{ borderBottom: '1px solid', padding: '8px 0 8px 4px' }}>
                                <Col span={19}>{customers.find(item => item.id == row.id).name}</Col>
                                <Col span={3}>
                                    <Switch 
                                        checked={row.executed}
                                        onChange={(val) => this.changeCustomer(row.id, val) }
                                    ></Switch>
                                </Col>
                                <Col span={2}><Button type="danger" onClick={() => this.rmCustomer(row.id)} ><Icon type="minus" /></Button></Col>
                            </Row>
                            )

                        )}                        
                    </Row>
                    )}
                    <Row align="bottom" type="flex">
                        <Col span={21}>
                            <Form.Item label="Empresa">
                                {getFieldDecorator('customer_id', {
                                    initialValue: null,
                                    
                                })(
                                    <Select
                                        
                                        showSearch
                                        placeholder="Selecione uma empresa"
                                        optionFilterProp="children"                                    
                                        onChange={(val) => this.setState({...this.state, temp_customer_id: val})}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                    <Option value="not_have">Não possui ou não é cliente</Option>
                                    {customers!==null && customers.map(row => 
                                        <Option value={row.id} key={row.id}>{`${row.cod} - ${row.name}`}</Option>
                                    )}
                                </Select>
                                )}
                            </Form.Item>
                        </Col>   
                        <Col offset={1} span={2}>
                            <Form.Item>
                                <Button disabled={this.state.temp_customer_id == null} onClick={this.addCustomer} type="primary"><Icon type="plus" /></Button>
                            </Form.Item>
                        </Col>   
                    </Row>              
                    
                    <Col span={8}>
                        <Form.Item label="Mês de Vencimento">
                            {getFieldDecorator('date', {
                                initialValue: data.date
                            })(
                                <Select placeholder="Selecione">
                                    {_.range(1, 13).map((i) => (
                                        <Option key={i} value={i}>{getMonthName(i)}</Option>
                                    ))}                                   
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Ano de Vencimento">
                            {getFieldDecorator('year_base', {
                                initialValue: data.year_base
                            })(
                                <Select placeholder="Selecione">
                                    {years(5).map(year => (
                                    <Option key={year} value={year}>{year}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    
                    <Col span={24}>
                        <Upload {...dataFiles}>
                            <Button>
                                <Icon type="upload" /> Anexar PDF
                            </Button>
                        </Upload>
                    </Col>            
                </Row>
            </Form>
            </div>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button
                style={{
                    marginRight: 8,
                }}
                onClick={this.props.onClose}
                >
                Cancelar
                </Button>
                {(data.status === 0 && customersDoneLength === customersLength) && data.files.length > 0 && (
                <Button onClick={this.onDone} className="btn btn-success" type="default" style={{marginRight: '10px'}}>Concluído</Button>
                )}
                {(data.id)?
                <Button onClick={this.onSave} type="primary">Salvar</Button>
                :
                <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
                }
            </div>
            </Drawer>
        </div>
        );
    }
}


const mapDispatchProps = dispatch => {
    return{
        registerConvention: (data) => dispatch({
        type: 'REGISTER_CONVENTION',
        payload: {
          request:{
            method: 'post',
            url:'/conventions',
            data: data
          }
        }
      }),
      saveConvention: (id, data) => dispatch({
        type: 'SAVE_CONVENTION',
        payload: {
          request:{
            method: 'post',
            url:`/conventions/${id}`,
            data: data
          }
        }
      })
    }
}

const mapStateToProps = state => {
    return {
        customers: state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer === 1).sort((a,b) => compare()(a.cod, b.cod))
    }
}
  
export default connect(mapStateToProps, mapDispatchProps)(Form.create()(ConventionsForm));