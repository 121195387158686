import React from 'react';
import { Form, Col, Row, Input, Select, Divider, Switch, Radio, Progress, Button } from 'antd';

import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';

import SelectWithAdd from '~/components/UI/SelectWithAdd';
import { InstallmentsTable } from '../Tables/InstallmentsTable';
import { PERMISSIONS } from '~/constants/permissions.constants';

import AssignTo from '~/components/AssignTo';

const { Option, OptGroup } = Select;

class FormClose extends React.Component {
  state = {
    default: {
      id: null,
      customer_id: null,
      description: null,
      amount: 0.00,
      create_payments: false,
      paid_off: false,
      pendency: null
    }
  };

  createBills = (val) => {

    if (val) {
      this.props.openBillForm({
        task_name: 'Encerramento da Empresa',
        total: this.props.form.getFieldValue('amount')
      });
    } else {
      this.props.setBillsData(null);
    }
  }

  getInitialCustomer = (data) => {
    if (!data.deleted_customer) {
      return data.customer_id;
    }

    return `${data.deleted_customer.cod} - ${data.deleted_customer.name}`;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { customers, user, form } = this.props;

    const data = {
      ...this.state.default,
      ...this.props.data
    };

    return (
      <Form>
        <Row gutter={16} className="mb-30">
          {(data.customer_id === null || data.id === null) && (
          <Col span={24}>
            <Form.Item label="Empresa">
              {getFieldDecorator('customer_id', data.customer_id ? {
                initialValue: this.getInitialCustomer(data),
                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
              } : {
                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
              })(
                <SelectWithAdd
                  placeholder="Selecione uma empresa"
                  disabled={!!data.id}
                  onChange={val => this.setState({ default: { ...this.state.default, customer_id: val } })}
                  setForm={(val) => this.props.form.setFieldsValue({ customer_id: val })}
                >
                  <OptGroup label="Clientes">
                    {customers.filter(row => row.is_customer === 1).map(row =>
                      <Option value={row.id} key={row.id}>{row.cod ? `${row.cod} - ${row.name}` : row.name}</Option>
                    )}
                  </OptGroup>
                  <OptGroup label="Não Clientes">
                    {customers.filter(row => row.is_customer === false).map(row =>
                      <Option value={row.id} key={row.id}>{row.cod ? `${row.cod} - ${row.name}` : row.name}</Option>
                    )}
                  </OptGroup>
                </SelectWithAdd>
              )}
            </Form.Item>
          </Col>
          )}
          <Col span={24}>
            <Form.Item label="Solicitação da diretoria">
              {getFieldDecorator('description', {
                initialValue: data.description,
                rules: [{ required: true, message: 'Descreva detalhes sobre o encerramento' }],
              })(
                <Input.TextArea rows={4} placeholder="Descreva detalhes sobre o encerramento" disabled={(data.id != null && data.created_by !== user.id) && data.description !== null} />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Pendência">
              {getFieldDecorator('pendency', {
                initialValue: data.pendency,
                rules: [{ required: true, message: 'Selecione quem é responsavél pela pendência' }],
              })(
                <Radio.Group onChange={(e) => this.setState({ pendency: e.target.value })}>
                  <Radio.Button value={0} size="small">Escritório</Radio.Button>
                  <Radio.Button value={1} size="small">Orgão Público</Radio.Button>
                  <Radio.Button value={2} size="small">Cliente</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <AssignTo form={form} initialValue={data.assigned_to} />
          </Col>
          <Col span={24}>
            <Form.Item label="Resposta do executor">
              {getFieldDecorator('answer', {
                initialValue: data.answer,
              })(
                <Input.TextArea rows={4} placeholder="Descreva detalhes adicionais" />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default FormClose;