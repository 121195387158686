import React from 'react';
import { Table, Button, Icon, Tag } from "antd";
import moment from "moment";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from "~/constants/storage.constants";
import { department } from "~/components/RegisterTasks/Utils";
import { getFormattedEvent } from "./helpers";
import { DeliveryMethodsWrapper } from './styles';
import { deliveryMethodNames } from '~/_domains/customers/drawer/data';
import { getStatusTag } from './index';

const renderFileUrl = (file_url, file) => (
  <a
    href={file_url}
    style={{ color: '#7c4dff'}}
    download
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon style={{ marginRight: 10 }} type="file" />
    {file.description.length > 20 ? `${file.description.substring(0, 20)}...` : file.description}
  </a>
);

const renderCustomerName = (_, record) => {
  const customerName = `${record.customer.cod} - ${record.customer.name}`;
  return customerName.length > 40 ? `${customerName.substring(0, 40)}...` : customerName;
};

const renderDepartment = (departmentId) => department(departmentId);

const renderCompetencyAt = (competency_at) => moment(competency_at).format("MM/YYYY");

const renderDueAt = (due_at) => moment(due_at).format("DD/MM/YYYY");

const renderAmount = (amount) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(amount);

const renderOffice = (userName, record) => {
  const createdAt = moment(record.created_at);
  const firstName = userName ? userName.split(' ')[0] : '';
  return `${firstName} ${createdAt.format('DD/MM/YYYY')} as ${createdAt.format('HH:MM')}`
};

const renderViewedAt = (viewed_at, file) => {
  const appUser = file.viewed_by_app_user;
  if (!viewed_at || !appUser) return null;
  const firstAppUserName = appUser.name.split(" ")[0];
  const formattedViewedAt = moment(viewed_at).format('DD/MM/YYYY [as] LTS');
  return `${firstAppUserName}... ${formattedViewedAt}`;
};

const renderEmailStatus = (emailStatus, { id }, openEmailLogsModal, sendEmails) => {
  if (!emailStatus) {
    return (
      <Tag
        style={{ cursor: 'pointer' }}
        onClick={() => sendEmails(id)}
      >
        Enviar e-mail
      </Tag>
    )
  }
  const status = getFormattedEvent(emailStatus);
  return (
    <Tag
      style={{ cursor: 'pointer' }}
      color={status.color}
      onClick={() => openEmailLogsModal(id)}
    >
        {status.text}
    </Tag>
  )
};

const renderDeliveryMethods = (deliveryMethods, { protocol_id }) => (
  <DeliveryMethodsWrapper>
    {deliveryMethods && deliveryMethods.map((method) => (
      <Tag key={method}>{deliveryMethodNames[method]}{method === 1 && `#${protocol_id}`}</Tag>
    ))}
  </DeliveryMethodsWrapper>
);

const renderCreatedAt = (created_at) =>
  `${moment(created_at).format('DD/MM/YYYY')} as ${moment(created_at).format('LTS')}`;

const renderAction = (_, file, impersonateAccess, impersonateAccessLoading) => (
  <Button
    icon="link"
    onClick={() => impersonateAccess(file)}
    size="small"
  >
    {impersonateAccessLoading === file.id ? 'Gerando acesso...' : 'Painel do cliente'}
  </Button>
);

const renderArchive = (id, openArchiveModal) => (
  <Button
    type="danger"
    icon="delete"
    onClick={() => openArchiveModal(id)}
  />
);

const DocumentsTable = ({
  documents,
  isLoading,
  impersonateAccess,
  openArchiveModal,
  openEmailLogsModal,
  generateAccessToFile,
  getRevisedTitle,
  visualizationType,
  uniqueTaskNames,
  setFilters,
  setVisualizationType,
  filters,
  props,
  impersonateAccessLoading
}) => {
  const columns = [
    {
      title: "Arquivo",
      dataIndex: "file_url",
      key: "file_url",
      fixed: "left",
      width: 250,
      ellipsis: true,
      render: renderFileUrl
    },
    {
      title: "Cliente",
      key: "customer",
      dataIndex: "customer.name",
      fixed: "left",
      width: 450,
      ellipsis: true,
      render: renderCustomerName
    },
    {
      title: "Nome da tarefa",
      key: "task_name",
      dataIndex: "task_name",
      align: "center",
      filters: uniqueTaskNames.map((task) => ({
        text: task,
        value: task,
      })),
      filterMultiple: false,
      onFilter: (value, file) => file.task_name === value,
      width: 200,
      ellipsis: true
    },
    {
      title: "Tipo",
      key: "type",
      dataIndex: "type",
      align: "center",
      width: 200,
      ellipsis: true
    },
    {
      title: "Colaborador",
      key: "created_by_user",
      dataIndex: "created_by_user",
      width: 150,
      align: "center",
      ellipsis: true
    },
    {
      title: "Departamento",
      key: "department",
      dataIndex: "department",
      align: "center",
      filters: [
        { text: department(1), value: "1" },
        { text: department(2), value: "2" },
        { text: department(3), value: "3" },
        { text: department(4), value: "4" },
        { text: department(5), value: "5" },
        { text: department(6), value: "6" },
      ],
      filterMultiple: false,
      onFilter: (value, file) => {
        if (!file.department) return false;
        return file.department.indexOf(value) === 0;
      },
      render: renderDepartment,
      width: 150,
      ellipsis: true
    },
    {
      title: "Competência",
      key: "competency_at",
      dataIndex: "competency_at",
      align: "center",
      sorter: (a, b) => moment(a.competency_at).diff(b.competency_at),
      render: renderCompetencyAt,
      width: 150,
      ellipsis: true
    },
    {
      title: "Vencimento",
      key: "due_at",
      dataIndex: "due_at",
      align: "center",
      sorter: (a, b) => moment(a.due_at).diff(b.due_at),
      render: renderDueAt,
      width: 140,
      ellipsis: true
    },
    {
      title: "Valor",
      key: "amount",
      dataIndex: "amount",
      align: "center",
      render: renderAmount,
      width: 130,
      ellipsis: true
    },
    {
      title: "Escritório",
      key: "office",
      dataIndex: "created_by_user",
      align: "center",
      render: renderOffice,
      width: 300,
      ellipsis: true
    },
    {
      title: "Administração",
      key: "viewed_at",
      dataIndex: "viewed_at",
      align: "center",
      width: 250,
      ellipsis: true,
      render: renderViewedAt
    },
    {
      align: "center",
      title: "Status",
      key: "status",
      width: 200,
      filters: [
        { text: 'Vencido', value: 'Vencido' },
        { text: 'Baixado', value: 'Baixado' },
        { text: 'Não baixado', value: 'Não baixado' },
      ],
      filterMultiple: false,
      onFilter: (value, file) => {
        if (value === 'Vencido') {
          return moment(file.due_at).add(1, "day") < moment() && !file.viewed_at;
        } else if (value === 'Baixado') {
          return !!file.viewed_at;
        } else if (value === 'Não baixado') {
          return moment(file.due_at).add(1, "day") > moment() && !file.viewed_at;
        }
      },
      render: (_, file) => getStatusTag(file),
      ellipsis: true
    },
    {
      align: "center",
      title: "Status do e-mail",
      dataIndex: "email_status",
      key: "email_status",
      width: 150,
      filters: [
        { text: 'Rascunho', value: 'draft' },
        { text: 'Enviado', value: 'delivered' },
        { text: 'Aberto', value: 'opened' },
        { text: 'Clicado', value: 'clicked' },
        { text: 'Marcado como spam', value: 'complained' },
        { text: 'Falhou', value: 'failed' },
        { text: 'Inscrição cancelada', value: 'unsubscribed' },
        { text: 'Envio solicitado', value: 'pendent' },
        { text: 'Blacklist', value: 'blacklist' },
      ],
      onFilter: (value, record) => record.email_status === value,
      render: (emailStatus, record) => renderEmailStatus(emailStatus, record, openEmailLogsModal, props.sendEmails),
      ellipsis: true
    },
    visualizationType === 'archived' && {
      title: "Arquivado em",
      key: "archived_at",
      dataIndex: "archived_at",
      render: (archivedAt) => moment(archivedAt).format('DD/MM/YYYY [as] H:m:s'),
      width: 150,
      ellipsis: true
    },
    visualizationType === 'archived' && {
      title: "Arquivado por",
      key: "archived_by",
      dataIndex: "archived_by",
      render: (archivedBy) => {
        const { getUser } = props;
        const user = getUser(archivedBy);
        return user ? user.name : null
      },
      width: 150,
      ellipsis: true
    },
    visualizationType === 'archived' && {
      title: "Motivo por arquivar",
      key: "reason_to_archive",
      dataIndex: "reason_to_archive",
      width: 200,
      ellipsis: true
    },
    {
      title: "Métodos de envio",
      key: "delivery_methods",
      filters: [
        { text: 'Email', value: 0 },
        { text: 'Protocolo', value: 1 },
        { text: 'Painel Web', value: 2 },
        { text: 'WhatsApp', value: 3 },
      ],
      onFilter: (value, record) => record.delivery_methods.includes(value),
      dataIndex: "delivery_methods",
      width: 200,
      ellipsis: true,
      render: renderDeliveryMethods
    },
    {
      title: "Criado em",
      key: "created_at",
      dataIndex: "created_at",
      align: "center",
      sorter: (a, b) => moment(a.created_at).diff(b.created_at),
      defaultSortOrder: "descend",
      render: renderCreatedAt,
      width: 200,
      ellipsis: true
    },
    {
      key: "action",
      align: "center",
      render: (_, file) => renderAction(_, file, impersonateAccess, impersonateAccessLoading),
      width: 230,
      ellipsis: true
    },
    visualizationType !== 'archived' && {
      key: "archive",
      dataIndex: "id",
      align: "center",
      render: (id) => renderArchive(id, openArchiveModal),
      width: 80,
      ellipsis: true
    }
  ].filter(Boolean);

  const totalWidth = columns.reduce((sum, col) => sum + col.width, 0);

  return (
    <Table
      rowKey="id"
      bordered
      loading={isLoading}
      style={{ marginTop: 20 }}
      dataSource={documents}
      scroll={{ x: totalWidth, y: 'calc(100vh - 370px)' }}
      pagination={getDefaultPaginationConfig({
        storageKey: STORAGE.defaultPageSize.documents,
      })}
      columns={columns}
    />
  );
};

export default DocumentsTable;
