import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Row, Input, Select, Divider, Switch, AutoComplete, Radio, Progress, Button, Table } from 'antd';

import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import SelectWithAdd from '~/components/UI/SelectWithAdd';
import { InstallmentsTable } from '../Tables/InstallmentsTable';
import { PERMISSIONS } from '~/constants/permissions.constants';

import AssignTo from '~/components/AssignTo';

const { Option, OptGroup } = Select;

const FormTask = (props) => {
  const [defaultState, setDefaultState] = useState({
    id: null,
    customer_id: null,
    description: null,
    amount: 0.00,
    create_payments: false,
    paid_off: false,
    pendency: null
  });
  
  const formRef = useRef(null);

  const createBills = (val) => {
    if (val) {
      props.openBillForm({
        task_name: props.form.getFieldValue('task'),
        total: props.form.getFieldValue('amount')
      });
    } else {
      props.setBillsData(null);
    }
  }

  const getInitialCustomer = (data) => {
    if (!data.deleted_customer) {
      return data.customer_id;
    }
    return `${data.deleted_customer.cod} - ${data.deleted_customer.name}`;
  }

  const { getFieldDecorator, getFieldValue } = props.form;
  const { customers, user, form } = props;

  const data = {
    ...defaultState,
    ...props.data
  };

  const payment = props.billsPayment;
  const billsData = props.billsData;

  return (
    <Form layout="vertical" ref={formRef}>
      <Row gutter={16} className="mb-30">
        {(data.customer_id === null || data.id === null) && (
        <Col span={24}>
          <Form.Item label="Empresa">
            {getFieldDecorator('customer_id', data.customer_id ? {
              initialValue: getInitialCustomer(data),
              rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
            } : {
              rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
            })(
              <SelectWithAdd
                placeholder="Selecione uma empresa"
                disabled={!!data.id}
                onChange={val => setDefaultState(prevState => ({ ...prevState, customer_id: val }))}
                setForm={(val) => props.form.setFieldsValue({ customer_id: val })}
              >
                <OptGroup label="Clientes">
                  {customers.filter(row => row.is_customer === 1).map(row =>
                    <Option value={row.id} key={row.id}>{row.cod ? `${row.cod} - ${row.name}` : row.name}</Option>
                  )}
                </OptGroup>
                <OptGroup label="Não Clientes">
                  {customers.filter(row => row.is_customer === false).map(row =>
                    <Option value={row.id} key={row.id}>{row.cod ? `${row.cod} - ${row.name}` : row.name}</Option>
                  )}
                </OptGroup>
              </SelectWithAdd>
            )}
          </Form.Item>
        </Col>
        )}
        <Col span={24}>
          <Form.Item label="Tarefa">
            {getFieldDecorator('task', {
              initialValue: data.task,
              rules: [{ required: true, message: 'Por favor digite o nome da tarefa!' }],
            })(
              <AutoComplete
                dataSource={['IRPF', 'REGARE', 'Parcelamento', 'Visita em orgão público']}
                style={{ width: `100%` }}
                filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                placeholder="Digite o nome da tarefa"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Solicitação da diretoria">
            {getFieldDecorator('description', {
              initialValue: data.description,
              rules: [{ required: true, message: 'Descreva detalhes sobre o serviço!' }],
            })(
              <Input.TextArea rows={4} placeholder="Descreva detalhes sobre o serviço" disabled={((data.id != null && data.created_by !== user.id) && data.description !== null)} />
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Pendência">
            {getFieldDecorator('pendency', {
              initialValue: data.pendency,
              rules: [{ required: true, message: 'Selecione quem é responsavél pela pendência' }],
            })(
              <Radio.Group onChange={(e) => setDefaultState(prevState => ({ ...prevState, pendency: e.target.value }))}>
                <Radio.Button value={0} size="small">Escritório</Radio.Button>
                <Radio.Button value={1} size="small">Orgão Público</Radio.Button>
                <Radio.Button value={2} size="small">Cliente</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <AssignTo form={form} initialValue={data.assigned_to} />
        </Col>
        <Col span={24}>
          <Form.Item label="Resposta do executor">
            {getFieldDecorator('answer', {
              initialValue: data.answer,
            })(
              <Input.TextArea rows={4} placeholder="Descreva detalhes adicionais" />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default FormTask;