/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Drawer,
  Form,
  Button,
  Icon,
  Modal,
  Steps,
  Divider,
  Tooltip,
  Tabs,
  Dropdown,
  Menu,
  message,
} from 'antd';
import { compare } from 'natural-orderby';
import { get } from 'lodash';
import moment from 'moment';
import { DrawerFooter } from '~/components/UI/Drawer';
import FormCustomer from '~/containers/DrawerSocietario/Forms/Customer/Index';
import Attachments from '~/containers/Attachments';
import FormChange from './Forms/Change';
import FormClose from './Forms/Close';
import FormTask from './Forms/Task';
import BillsForm from './Forms/BillsForm';
import Download from '~/components/Components/Download';
import { LeadCard, LeadCardId, LeadSource } from './style';

import { Cod } from '~/components/UI';
import * as drawerActions from './actions';
import * as societarioActions from '~screens/Societario/actions';
import * as customersActions from '~/_domains/customers/drawer/actions';
import * as refundsActions from '~containers/DrawerRefunds/actions';
import * as drawerLead from '~containers/DrawerLeads/actions';
import { handleContractFiles } from '~/_domains/customers/drawer/methods/handle-contract-files';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { handlePartnersSets } from '~/_domains/customers/drawer/methods/handle-partners-sets';
import Checklist from './components/Checklist';
import Financial from './components/Financial';
import { getNameDrawer, getCurrentStep } from './helpers';

const { Step } = Steps;
const { TabPane } = Tabs;

const DrawerSocietario = (props) => {
  const [prepareData, setPrepareData] = useState({});
  const [isBillsFormVisible, setIsBillsFormVisible] = useState(false);
  const [billsFormParams, setBillsFormParams] = useState({});
  const [billsData, setBillsData] = useState(null);
  const [billsPayment, setBillsPayment] = useState(null);
  const [contractFiles, setContractFiles] = useState([]);
  const [partnersSets, setPartnersSets] = useState([]);
  const [uploadkey] = useState(Math.random().toString(36).substr(2, 10));
  const [tasks, setTasks] = useState([]);

  const {
    close,
    drawer,
    form,
    customers,
    user,
    loadCustomers,
  } = props;

  const { category } = drawer;
  const data = {
    ...get(drawer, 'data.custom_fields'),
    ...get(drawer, 'data'),
  };

  const isArchived = get(data, 'archived_at', null) !== null;

  useEffect(() => {
    if (data.id) {
      setContractFiles([...data.contract_files, ...data.rescission_files]);
      setPartnersSets(
        data.partners_sets.map((partnersSet) => ({
          ...partnersSet,
          formatted_change_date: moment(partnersSet.change_date).format(
            'DD/MM/YYYY'
          ),
        }))
      );
    }
  }, [data.id]);

  useEffect(() => {
    const tasksFromApi = get(drawer, 'data.tasks', []);
    if (Array.isArray(tasksFromApi)) {
      setTasks(tasksFromApi);
    }
  }, [data.id]);

  useEffect(() => {
    setBillsPayment(props.drawer.data.payment);
  }, [props.drawer]);

  const handleRequestFormData = (values, formData) => {
    handleContractFiles(contractFiles, formData);
    handlePartnersSets(partnersSets, formData);

    const fields = {
      ...values,
      category,
      ...prepareData,
      ...billsData,
      lead_id: drawer.data.lead_id,
      tasks: tasks,
    };

    fields.registered_at = moment(fields.registered_at).format('YYYY-MM-DD');
    fields.resp_compet_at = moment(fields.resp_compet_at).format('YYYY-MM-DD');
    fields.resp_due_at = moment(fields.resp_due_at).format('YYYY-MM-DD');
    fields.apuration_at = moment(fields.apuration_at).format('YYYY-MM-DD');
    fields.conciliation = fields.conciliation ? 1 : 0;
    fields.show_in_federal = fields.show_in_federal ? 1 : 0;
    fields.show_in_state = fields.show_in_state ? 1 : 0;
    fields.show_in_municipal = fields.show_in_municipal ? 1 : 0;
    fields.show_in_fgts = fields.show_in_fgts ? 1 : 0;

    Object.keys(fields).forEach((key) => {
      if (key === 'honorary_history') {
        const honoraryHistory = fields[key];
        honoraryHistory.forEach((item) => {
          formData.append('honorary_history[]', JSON.stringify(item));
        });
      } else if (key === 'honorary_history_deleted') {
        const honoraryHistoryDeleted = fields[key];
        honoraryHistoryDeleted.forEach((item) => {
          formData.append('honorary_history_deleted[]', item);
        });
      } else if (key === 'payments') {
        const payments = fields[key];
        Object.values(payments).forEach((payment, index) => {
          payment.due_date = moment(payment.due_date).format('YYYY-MM-DD');
          formData.append(`payments[${index}]`, JSON.stringify(payment));
        });
      } else if (key === 'tasks') {
        const tasks = fields[key];
        formData.append('tasks', tasks.length > 0 ? JSON.stringify(tasks) : '[]');
      } else if (key === 'assigned_to') {
        fields[key].map((item, index) => {
          formData.append(`assigned_to[${index}]`, item);
        });
      } else if (fields[key] !== null && fields[key] !== undefined) {
        formData.append(key, fields[key]);
      }
    });
  };

  const onCreate = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (category === 1 || category === 5) {
        const formData = new FormData();
        handleRequestFormData(values, formData);

        props.createItem(formData).then(() => {
          setPrepareData({});
          setContractFiles([]);
          setPartnersSets([]);
          setBillsData(null);
          setTasks([]);
          form.resetFields();
          props.close();
          if (category === 1 || category === 5) {
            loadCustomers();
          }
        });
      } else {
        props
          .createItem({
            ...values,
            category,
            ...prepareData,
            ...billsData,
            uploadkey: uploadkey,
            tasks: tasks,
          })
          .then(() => {
            setPrepareData({});
            setContractFiles([]);
            setPartnersSets([]);
            setBillsData(null);
            setTasks([]);
            form.resetFields();
            props.close();
            if (category === 1 || category === 5) {
              loadCustomers();
            }
          });
      }
    });
  };

  const onSave = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (category === 1 || category === 5) {
        const formData = new FormData();
        handleRequestFormData(values, formData);

        props.saveItem(data.id, formData).then(() => {
          setPrepareData({});
          setContractFiles([]);
          setPartnersSets([]);
          setTasks([]);
          form.resetFields();
          props.close();
          if (category === 1 || category === 5) {
            loadCustomers();
          }
        });
      } else {
        props
          .saveItem(data.id, {
            ...values,
            category,
            ...prepareData,
            ...billsData,
            tasks,
          })
          .then(() => {
            setPrepareData({});
            setContractFiles([]);
            setPartnersSets([]);
            setTasks([]);
            form.resetFields();
            props.close();
            if (category === 1 || category === 5) {
              loadCustomers();
            }
          });
      }
    });
  };

  const onDelete = () => {
    Modal.confirm({
      title: 'Você realmente deseja excluir?',
      content: 'Você não poderá reverter esta ação no futuro.',
      okText: 'Sim',
      okType: 'warning',
      cancelText: 'Cancelar',
      onOk: () => {
        props.deleteItem(data.id);
        setPrepareData({});
        props.close();
      },
    });
  };

  const onArchive = () => {
    Modal.confirm({
      title: 'Você realmente deseja arquivar?',
      content: 'Você poderá reverter esta ação no futuro se necessário.',
      okText: 'Sim',
      okType: 'warning',
      cancelText: 'Cancelar',
      onOk: () => {
        props.archiveItem(data.id);
        setPrepareData({});
        props.close();
      },
    });
  };

  const unArchive = () => {
    Modal.confirm({
      title: 'Você realmente deseja desarquivar?',
      content: 'Você poderá reverter esta ação no futuro se necessário.',
      okText: 'Sim',
      okType: 'warning',
      cancelText: 'Cancelar',
      onOk: () => {
        props.unArchiveItem(data.id);
        setPrepareData({});
        props.close();
      },
    });
  };

  const openBillForm = (params) => {
    setIsBillsFormVisible(true);
    setBillsFormParams(params);
  };

  const getPaymentPercentage = (payment) => {
    if (!payment) return 0;
    const paidInstallments = payment.installments.filter(
      (installment) => installment.status === 'Pago'
    );
    return Math.round(
      (100 * paidInstallments.length) / payment.installments.length
    );
  };

  const getFilename = (data) => {
    return `ticket - ${data.id}.pdf`;
  };

  if (!get(drawer, 'show')) {
    return <div></div>;
  }

  return (
    <Drawer
      title={
        get(data, 'id') ? (
          <span>
            {getNameDrawer(category)}{' '}
            <Cod float="right">#SOC{data.id}</Cod>
          </span>
        ) : (
          getNameDrawer(category)
        )
      }
      width={900}
      zIndex={999}
      placement="right"
      onClose={() => {
        setBillsData(null);
        setContractFiles([]);
        setPartnersSets([]);
        close();
      }}
      maskClosable={false}
      visible={get(drawer, 'show')}
      style={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 53,
      }}
      destroyOnClose
    >
      <div className="card-container">
        <Tabs type="card">
          <TabPane tab="Ticket" key="1" size="large" style={{ padding: 16 }} forceRender>
            {get(data, 'id') && (
              <div>
                <Steps progressDot current={getCurrentStep(data)} size="small">
                  <Step
                    title="Cadastrado"
                    description={moment(get(data, 'created_at')).format(
                      'DD/MM/YYYY'
                    )}
                  />
                  <Step
                    title="Em andamento"
                    description={
                      get(data, 'processed_at')
                        ? moment(get(data, 'processed_at')).format('DD/MM/YYYY')
                        : ''
                    }
                  />
                  <Step
                    title="Finalizado"
                    description={
                      get(data, 'finalized_at')
                        ? moment(get(data, 'finalized_at')).format('DD/MM/YYYY')
                        : ''
                    }
                  />
                </Steps>
                <Divider />
              </div>
            )}

            {/* Lead Card */}
            {user.permissions.includes(PERMISSIONS.leads.view) && data.lead_id && (
              <Tooltip
                title="Clique para visualizar as informações do lead vinculado"
                placement="left"
              >
                <LeadCard
                  style={{ marginBottom: 16 }}
                  onClick={() => props.openDrawerLead({ ...data.lead })}
                >
                  {get(data, 'lead.name')}
                  <LeadCardId>#{get(data, 'lead.id')}</LeadCardId>
                  <LeadSource>
                    <Icon type="notification" />
                  </LeadSource>
                </LeadCard>
              </Tooltip>
            )}

            {/* Customer Card */}
            {data.customer && category !== 1 && category !== 5 && (
              <Tooltip
                title="Clique para visualizar as informações do cliente vinculado"
                placement="left"
              >
                <LeadCard
                  style={{ marginBottom: 16 }}
                  onClick={() => {
                    if (user.permissions.includes(PERMISSIONS.customers.view)) {
                      props.openCustomerDrawer({ ...data.customer });
                    }
                  }}
                >
                  {get(data, 'customer.name')}
                  <LeadCardId>#{get(data, 'customer.id')}</LeadCardId>
                  <LeadSource>
                    <Tooltip title="Cliente vinculado">
                      <Icon type="bank" />
                    </Tooltip>
                  </LeadSource>
                </LeadCard>
              </Tooltip>
            )}

            {(category === 2 || category === 1 || category === 5) && (
              <FormChange
                category={category}
                data={data}
                form={form}
                customers={customers}
                user={user}
              />
            )}
            {(category === 3 || category === 7) && (
              <FormClose
                data={data}
                form={form}
                customers={customers}
                user={user}
              />
            )}
            {category === 4 && (
              <FormTask
                data={data}
                form={form}
                customers={customers}
                user={user}
              />
            )}
          </TabPane>

          {(category === 1 || category === 5) && (
            <TabPane
              forceRender
              tab="Empresa"
              key="5"
              size="large"
              style={{ padding: 16 }}
            >
              <FormCustomer
                data={{
                  ...data,
                  contract_files: contractFiles,
                  partners_sets: partnersSets,
                }}
                billsPayment={billsPayment}
                billsData={billsData}
                form={form}
                customers={customers}
                user={user}
                dashboard={true}
                category={category}
                getPaymentPercentage={getPaymentPercentage}
                setBillsData={setBillsData}
                openBillForm={openBillForm}
                setPrepareData={(newData) => setPrepareData({ ...prepareData, ...newData })}
                updateContractFilesState={setContractFiles}
                setPartnersSets={setPartnersSets}
              />
            </TabPane>
          )}

          <TabPane
            forceRender
            tab="Checklist"
            key="4"
            size="large"
            style={{ padding: 16 }}
          >
            <Checklist data={data} tasks={tasks} setTasks={setTasks} />
          </TabPane>
          {user.permissions.includes(PERMISSIONS.corporate.financial.view) && (
            <TabPane
              tab="Financeiro"
              key="3"
              size="large"
              style={{ padding: 16 }}
            >
              <Financial
                data={data}
                form={form}
                billsData={billsData}
                customers={customers}
                billsPayment={billsPayment}
                user={user}
                getPaymentPercentage={getPaymentPercentage}
                setBillsData={setBillsData}
                openBillForm={openBillForm}
                category={category}
              />
            </TabPane>
          )}
          <TabPane
            forceRender
            tab="Anexos"
            key="2"
            size="large"
            style={{ padding: 16 }}
          >
            <Attachments
              source={'dash_tasks'}
              entity={data.id ? data.id : uploadkey}
              initialFiles={[]}
            />
          </TabPane>
        </Tabs>
      </div>
      <DrawerFooter>
        {get(data, 'id') && !isArchived && (
          <Download url={`/dashtasks/print/${data.id}`} filename={getFilename(data)}>
            <Button style={{ marginRight: 8 }} type="primary">
              <Icon type="printer" /> Imprimir
            </Button>
          </Download>
        )}
        {get(data, 'id') && user.permissions.includes(PERMISSIONS.corporate.createBillsToPay) && (
          <Dropdown
            overlay={
              <Menu>
                {!isArchived && (
                  <Menu.Item key="0" onClick={onArchive}>
                    <Icon type="file" /> Arquivar
                  </Menu.Item>
                )}
                {isArchived && (
                  <Menu.Item key="0" onClick={unArchive}>
                    <Icon type="file" /> Desarquivar
                  </Menu.Item>
                )}
                <Menu.Item key="1" onClick={onDelete}>
                  <Icon type="delete" /> Deletar
                </Menu.Item>
              </Menu>
            }
          >
            <Button style={{ marginRight: 8 }} type="danger">
              Ações <Icon type="down" />
            </Button>
          </Dropdown>
        )}
        {get(data, 'id') && !isArchived && (
          <Button
            style={{ marginRight: 8 }}
            type="info"
            onClick={() => {
              props.openDrawerRefunds({
                refund: {
                  dash_task_id: get(data, 'id'),
                  customer_id: get(data, 'customer_id'),
                },
              });
            }}
          >
            <Icon type="plus" /> Comissão
          </Button>
        )}

        {!isArchived && get(data, 'id') && (
            <Button onClick={onSave} type="primary">
              Salvar
            </Button>
        )}
        {!get(data, 'id') && !isArchived && (
            <Button onClick={onCreate} type="primary">
              Cadastrar
            </Button>
        )}

      </DrawerFooter>
      <BillsForm
        visible={isBillsFormVisible}
        setVisible={setIsBillsFormVisible}
        billsData={billsData}
        setBillsData={setBillsData}
        setBillsPayment={setBillsPayment}
        billsPayment={billsPayment}
        data={{
          ...billsFormParams,
          ...data,
        }}
        user={user}
      />
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  drawer: get(state, 'drawerSocietario.drawer'),
  customers: state.customers.list
    .filter((row) => row.inactived_at === null)
    .sort((a, b) => compare()(a.cod, b.cod)),
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  createItem: (payload) => dispatch(drawerActions.createItem(payload)),
  saveItem: (id, data) => dispatch(drawerActions.saveItem(id, data)),
  deleteItem: (id) => dispatch(drawerActions.deleteItem(id)),
  archiveItem: (id) => dispatch(drawerActions.archiveItem(id)),
  unArchiveItem: (id) => dispatch(drawerActions.unArchiveItem(id)),
  close: () => dispatch(drawerActions.close()),
  registerBill: (payload) => dispatch(societarioActions.registerBill(payload)),
  saveBill: (id, payload) => dispatch(societarioActions.saveBill(id, payload)),
  loadCustomers: () => dispatch(customersActions.loadCustomers()),
  openDrawerRefunds: (payload) => dispatch(refundsActions.show(payload)),
  openDrawerLead: (payload) => dispatch(drawerLead.show(payload)),
  openCustomerDrawer: (payload) =>
    dispatch(customersActions.showDrawerCustomer(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(DrawerSocietario));
