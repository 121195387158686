import update from 'immutability-helper';
import get from 'lodash/get';
// import set from 'lodash/set';

const ACTION_HANDLERS = {

    '@@CUSTOMER/SHOW': (state, action) => {
        return update(state, {
            drawer: {
                id: { $set: get(action, 'payload.data.id', null) },
                show: { $set: true },
                openedFrom: { $set: get(action, 'payload.openedFrom') },
            }
        })
    },

    '@@CUSTOMER/CLOSE': (state, action) => {
        return update(state, {
            drawer: {
                data: { $set: {} },
                show: { $set: false },
                openedFrom: { $set: null },
                id: { $set: null },
            }
        })
    },

    '@@CUSTOMER/GET_BY_ID': (state) => {
        return update(state, {
            drawer: {
                loading: { $set: true },
            },
        });
    },
    '@@CUSTOMER/GET_BY_ID_SUCCESS': (state, action) => {
        return update(state, {
            drawer: {
                data: { $set: get(action, 'payload.data.data') },
                loading: { $set: false },
            },
        });
    },
    '@@CUSTOMER/GET_BY_ID_FAIL': (state) => {
        return update(state, {
            drawer: {
                loading: { $set: false },
            },
        });
    },
}

const defaultState = {
    drawer: {
        id: null,
        data: {},
        show: false,
        openedFrom: null,
        loading: false,
    }
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;