import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Upload, Table, Select, Steps, message, Button, Icon, Row, Col, Card } from "antd";
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import { Tip } from "~/components/Tip";

import { batch, loadCustomers } from "~/_domains/customers/drawer/actions";
import { generateTemplate } from "./downloadTemplate";
import { systemIndex, examplePerIndex } from "./systemIndex";
import { useAppContext } from '~/AppContext';

const { Step } = Steps;
const { Dragger } = Upload;
const { Option } = Select;

const ImportModal = ({ visible, onCancel }) => {
    const dispatch = useDispatch();
    const { setOnboardingTaskAsDone } = useAppContext();

    const [fileList, setFileList] = useState([]);
    const [data, setData] = useState([]);
    const [mapping, setMapping] = useState({});
    const [step, setStep] = useState(0);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleUpload = ({ file }) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet, { raw: false, dateNF: 'dd/mm/yyyy' });
            setData(json);
            setColumns(json[0] ? Object.keys(json[0]) : []);

            // Automatically map columns if they match the system columns
            const autoMapping = {};
            Object.keys(systemIndex).forEach((key) => {
                const column = json[0] ? Object.keys(json[0]).find(col => col.toLowerCase().replace(/\s/g, '') === systemIndex[key].label.toLowerCase().replace(/\s/g, '')) : null;
                if (column) {
                    autoMapping[key] = column;
                }
            });
            setMapping(autoMapping);

            // Update data with initial mapping
            const updatedData = json.map((row) => {
                return Object.keys(row).reduce((acc, fieldKey) => {
                    const systemKey = Object.keys(autoMapping).find(key => autoMapping[key] === fieldKey);
                    if (systemKey) {
                        acc[systemKey] = row[fieldKey];
                    } else {
                        acc[fieldKey] = row[fieldKey];
                    }
                    return acc;
                }, {});
            });
            setData(updatedData);

            setStep(1);
        };
        reader.readAsArrayBuffer(file);
        setFileList([file]);
    };

    function swapKeyValue(mapping) {
        const swapped = {};
        for (const [key, value] of Object.entries(mapping)) {
            swapped[value] = key;
        }
        return swapped;
    }

    const handleMappingChange = (value, key) => {
        const updatedMapping = { ...mapping, [key]: value };
        setMapping(updatedMapping);

        const updatedMappingSwapped = swapKeyValue(updatedMapping);

        const updatedData = data.map((row) => {
            return Object.keys(row).reduce((acc, fieldKey) => {
                const systemKey = updatedMappingSwapped[fieldKey];
                if (systemKey) {
                    acc[systemKey] = row[fieldKey];
                } else {
                    acc[fieldKey] = row[fieldKey];
                }
                return acc;
            }, {});
        });

        setData(updatedData);
    };

    const handleSubmit = () => {
        setLoading(true);

        const updatedData = data.map((customer) => {
            const observation = Object.keys(customer)
                .filter(key => !Object.keys(systemIndex).includes(key))
                .map(key => `${key}: ${customer[key]}`)
                .join('\n');

            return {
                ...customer,
                observation,
                entity_type: 'customer',
            };
        });

        dispatch(batch({ customers: updatedData }))
            .then(() => {
                dispatch(loadCustomers({ showLoading: false }));
                setLoading(false);
                setStep(0);
                setFileList([]);
                setData([]);
                setMapping({});
                onCancel();

                // Update the onboardingData
                setOnboardingTaskAsDone('m3n4o5');
            })
            .catch(() => {
                message.error('Erro ao importar clientes');
                setLoading(false);
            });
    };

    const renderMappingSelect = (group) => {
        return Object.keys(systemIndex)
            .filter(key => systemIndex[key].group === group)
            .map(key => (
                <div key={key} style={{ marginBottom: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ marginRight: 8, textAlign: 'right', width: 150 }}>{systemIndex[key].label}: </span>
                    <Select
                        style={{ width: 200 }}
                        onChange={(value) => handleMappingChange(value, key)}
                        placeholder="Selecione uma coluna"
                        value={mapping[key] || undefined}
                    >
                        {columns.map((col) => (
                            <Option key={col} value={col}>
                                {col}
                            </Option>
                        ))}
                    </Select>
                </div>
            ));
    };

    return (
        <Modal
            visible={visible}
            title="Importar clientes"
            onCancel={onCancel}
            onOk={step === 2 ? handleSubmit : () => {
                if(data && data.length) {
                    setStep(step + 1);
                } else {
                    message.warning('Selecione um arquivo para continuar');
                }
            }}
            okText={step === 2 ? 'Importar' : 'Próximo'}
            confirmLoading={loading}
            width={900}
        >
            <Steps current={step} style={{ marginBottom: 16 }}>
                <Step title="Upload" />
                <Step title="Identificar Colunas" />
                <Step title="Confirmar" />
            </Steps>
            {step === 0 && (
                <>
                    <Dragger
                        beforeUpload={() => false}
                        onChange={handleUpload}
                        fileList={fileList}
                    >
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                    </Dragger>
                    <Button
                        type="dashed"
                        style={{ width: '100%', marginTop: 16 }}
                        onClick={generateTemplate}
                        icon="download"
                    >
                        Baixar Modelo de Importação
                    </Button>
                    <div style={{ marginTop: 8, textAlign: 'center' }}>
                        <span>Preciso baixar o modelo? </span>
                        <Tip>Baixar o modelo de excel é opcional, você pode importar qualquer excel e na segunda etapa poderá mapear as colunas.</Tip>
                    </div>
                </>
            )}
            {step === 1 && (
                <div style={{ marginTop: 16, textAlign: 'center' }}>
                    <h3>Informações Básicas</h3>
                    {renderMappingSelect('Informações Básicas')}
                    <h3>Endereço</h3>
                    {renderMappingSelect('Endereço')}
                    <h3>Informações Financeiras</h3>
                    {renderMappingSelect('Informações Financeiras')}
                    <h3>Outras Informações</h3>
                    {renderMappingSelect('Outras Informações')}
                </div>
            )}
            {step === 2 && (
                <Table 
                    dataSource={data} 
                    columns={Object.keys(systemIndex).map(key => ({
                        title: systemIndex[key].label,
                        dataIndex: systemIndex[key].index,
                        key: systemIndex[key].index,
                        width: 150,
                        ellipsis: true,
                    }))}
                    rowKey={(record, index) => index} 
                    pagination={true}
                    scroll={{ x: '100%' }}
                    style={{ width: '100%' }}
                />
            )}
        </Modal>
    );
};

export default ImportModal;
