import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Switch, List, Tag, Input, Row, Col } from 'antd';
import { compare } from 'natural-orderby';

import FilterPopOver from '../UI/FilterPopover';
import * as calendarTasksActions from "~/_domains/calendarTasks/actions";

import { request } from '../Instance';

const Search = Input.Search;
const InputGroup = Input.Group;


class Customers extends React.Component {

    state = {
        search: '',
        filter: null,
        loading: true,
        customersChecked: [],
        isModified: false
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.task !== null && (this.props.task === null || nextProps.task.id !== this.props.task.id)){

            if (this.state.isModified) {
                this.setState({ isModified: false });
            }

            request('get', `/task/${nextProps.task.id}/customers`, {}, false)
            .then(response => {
                this.setState({ 
                    customersChecked: response.data, 
                    loading: false 
                });
            })
        }
    }

    filterCustomers = (search, filter) => {
        const { customers } = this.props;

        let filtered = (customers !== null)? customers.filter((str) => {

            let taxation = true;
            if((str.type_taxation !== null && filter !==null && str.type_taxation.toLowerCase().indexOf(filter.toLowerCase()) === -1) 
                || str.type_taxation === null && filter !==null){
                taxation = false;
            }

            if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 && taxation){
                return true;
            }

            if(str.email !== null && str.email.toLowerCase().indexOf(search.toLowerCase()) >= 0 && taxation){
                return true;
            }

            if(str.identification_number !== null && str.identification_number.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0 && taxation){
                return true;
            }

            if(str.cnpj_formated !== null && str.cnpj_formated.toLowerCase().indexOf(search.toLowerCase()) >= 0 && taxation){
                return true;
            }

            if(str.cod !== null && str.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0 && taxation){
                return true;
            }

            return false;
        }): [];

        filtered = filtered.sort((a,b) => compare()(a.cod, b.cod));

        return filtered;
    }

    handleCheckAll = (value) => {

        const filteredCustomers = this.filterCustomers(this.state.search, this.state.filter);

        const pendent = filteredCustomers.filter(row => this.state.customersChecked.indexOf(row.id) === -1);
        
        const mapPendent = pendent.map((row) => {
            return row.id
        });

        this.props.addCustomers(this.props.task.id, {
            checked: value,
            customers: value? mapPendent : this.state.customersChecked
        })
        .then(response => {
            this.goToNextOnboardingStep();
            this.setState({ 
                customersChecked: value?[
                    ...this.state.customersChecked, ...mapPendent]
                    :[], 
                loading: false,
                isModified: true
            });
        });
    }

    closeDrawer = () => {
        this.props.onClose();

        if (this.state.isModified) {
            this.props.loadAllDepartmentsCalendarTasks();
        }
    }

    render() {

        const { task, customers } = this.props;
        const { search, loading, customersChecked, filter } = this.state;

        const filteredCustomers = this.filterCustomers(search, filter);

        return (
        <div>
            {task && (
            <Drawer
                title={`${task.name} / Clientes`}
                width={720}
                placement="right"
                onClose={this.closeDrawer}
                maskClosable={true}
                visible={this.props.visible}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <Row className="mb-20">
                <Col span={12}>
                    {filteredCustomers.length > 0 && !loading && (
                    <div>
                    <Switch 
                        onChange={this.handleCheckAll} 
                        defaultChecked={(customersChecked.length === filteredCustomers.length)}
                    />
                    {' '}<span>Selecionar todos</span>
                    </div>
                    )}
                </Col>
                <Col span={12}>
                    <InputGroup style={{ width: '100%' }} compact>
                        <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%' }}>
                            <Col span={4} style={{textAlign: `right`}}>
                                <FilterPopOver 
                                    onChange={(val) => this.setState({filter: val})}
                                    options={[
                                        {value: 'Doméstica', label: 'Doméstica'},
                                        {value: 'Lucro Presumido', label: 'Lucro Presumido'},
                                        {value: 'Lucro Real', label: 'Lucro Real'},
                                        {value: 'Fiscal', label: 'Fiscal'},
                                        {value: 'MEI', label: 'MEI'},
                                        {value: 'Profissional Liberal', label: 'Profissional Liberal'},
                                        {value: 'Simples Nacional', label: 'Simples Nacional'},
                                    ]}
                                />
                            </Col>
                            <Col span={20}>
                            <Search 
                                placeholder="Pesquisar clientes" 
                                onChange={e => this.setState({search: e.target.value})}
                            />
                            </Col>
                        </Row>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-50">
                <List
                    loading={(customers.length === 0 || loading)}
                    rowKey="id"
                    dataSource={(filteredCustomers.length > 0 && !loading)?filteredCustomers:[]}
                    renderItem={item => (
                        <List.Item>
                            <Switch 
                                onClick={(value) => {
                                    this.setState({customersChecked: value? [
                                        ...customersChecked,
                                        item.id
                                    ]: customersChecked.filter(row => row !== item.id)});
                                    this.props.addCustomer(task.id, {customer_id: item.id, checked: value}).then(() => {
                                        this.goToNextOnboardingStep();
                                        this.setState({ isModified: true })
                                    })
                                }}
                                checked={(customersChecked.indexOf(item.id) !== -1)}
                            />
                            <div style={{marginLeft: `10px`}}>
                                {item.cod !==''?item.cod+' - ':''} {item.name} {item.type_taxation && (<Tag color="purple">{item.type_taxation}</Tag>)}
                            </div>
                        </List.Item>
                    )}
                />
            </Row>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button
                style={{
                    marginRight: 8,
                }}
                onClick={this.closeDrawer}
                >
                Fechar
                </Button>
            </div>
            </Drawer>
            )}
        </div>
        );
    }
}

const mapStateToProps = state => {
    return{
        customers: state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer === 1),
        user: state.user
    }
}

const mapDispatchProps = dispatch => {
  return{
    addCustomer: (id, data) => dispatch({
        type: 'TASK_ADD_CUSTOMER',
        payload: {
          request:{
            method: 'POST',
            url:`/task/${id}/add`,
            data: data
          }
        }
    }),
    addCustomers: (id, data) => dispatch({
        type: 'TASK_ADD_CUSTOMERS',
        payload: {
            request: {
                method: 'POST',
                url: `/task/${id}/add/all`,
                data
            }
        }
    }),
    loadAllDepartmentsCalendarTasks: () => dispatch(calendarTasksActions.loadAllDepartmentsCalendarTasks()),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(Customers);