import React from 'react';
import { Tag, Tooltip, Dropdown, Menu, Icon, Popover } from 'antd';
import Moment from 'react-moment';
import { compare } from 'natural-orderby';
import { get } from 'lodash';
import { toBRL } from '~/components/UI/InputCurrency';
import { deliveryMethod } from '~/_domains/customers/drawer/data';
import { displaySocCategory } from './helpers';

export const getCustomersTableColumns = ({
  actived,
  styleCompanyName,
  onAction,
  canEditCustomers,
  canDeleteCustomers,
  findUser,
  filterCompanyType,
}) => [
  {
    title: "Cod",
    dataIndex: "cod",
    key: "cod",
    fixed: "left",
    width: 100,
    sorter: (a, b) => compare()(a.cod, b.cod),
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'ascend',
  },
  {
    title: "Empresa",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    width: 500,
    sorter: (a, b) => a.name && a.name.localeCompare(b.name),
    render: (text, record) => <span style={styleCompanyName(record)}>{text}</span>,
  },
  {
    width: 200,
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email && a.email.localeCompare(b.email),
    ellipsis: true,
  },
  {
    title: "CNPJ",
    dataIndex: "cnpj_formated",
    key: "cnpj_formated",
    width: 200,
    sorter: (a, b) => a.identification_number && (a.identification_number - b.identification_number),
    ellipsis: true,
  },
  {
    title: "Telefone",
    dataIndex: "phone_formated",
    key: "phone_formated",
    width: 200,
    sorter: (a, b) => a.phone && (a.phone - b.phone),
    ellipsis: true,
  },
  {
    title: "Atividade",
    dataIndex: "company_type",
    key: "company_type",
    width: 200,
    sorter: (a, b) => a.company_type && a.company_type.localeCompare(b.company_type),
    filters: filterCompanyType,
    filterMultiple: false,
    onFilter: (value, record) => record.company_type_id === value,
    render: (text, record) => {
      const companyType = filterCompanyType.find(item => item.value === record.company_type_id);
      return companyType ? companyType.text : text;
    },
  },
  {
    ellipsis: true,
    title: "Dt. Entrada",
    dataIndex: "registered_at",
    key: "registered_at",
    width: 200,
    sorter: (a, b) => a.registered_at && a.registered_at.localeCompare(b.registered_at),
    render: key => key && (
      <Moment format="DD/MM/YYYY">
        {key}
      </Moment>
    ),
  },
  !actived && {
    ellipsis: true,
    title: "Inativado Em",
    dataIndex: "inactived_at",
    key: "inactived_at",
    width: 200,
    sorter: (a, b) => a.inactived_at && a.inactived_at.localeCompare(b.inactived_at),
    render: (text, record, index) => record.inactived_at && (
      <Tooltip
        placement="bottom"
        title={
          record.inactived_by ?
            `Inativado por ${findUser(record.inactived_by).name}` :
            'Inativado automaticamente'
        }>
        <Moment format="DD/MM/YYYY">
          {record.inactived_at}
        </Moment>
      </Tooltip>
    ),
  },
  {
    dataIndex: "actions",
    key: "actions",
    fixed: "right",
    width: 150,
    render: (text, record, index) => (
      <center key={index}>
        <Dropdown
          overlayStyle={{ zIndex: 999 }}
          overlay={
            <Menu onClick={(e) => onAction(e, record)}>
              <Menu.Item key="edit" disabled={!canEditCustomers}>
                <Icon type="edit"></Icon> Editar
              </Menu.Item>
              {record.inactived_at !== null && (
                <Menu.Item key="activate" disabled={!canEditCustomers || false}>
                  <Icon type="check"></Icon> Ativar
                </Menu.Item>
              )}
              <Menu.Divider />
              {record.inactived_at === null && !record.enabled && (
                <Menu.Item key="force_activate" disabled={!canEditCustomers || false}>
                  <Icon type="check"></Icon> Forçar Ativação
                </Menu.Item>
              )}
              {record.inactived_at === null && (
                <Menu.Item key="inactivate" disabled={!canEditCustomers || false}>
                  <Icon type="delete"></Icon> Inativar
                </Menu.Item>
              )}
              {record.inactived_at !== null && (
                <Menu.Item key="delete" disabled={!canDeleteCustomers || false}>
                  <Icon type="delete"></Icon> Excluir
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <Popover
            placement="left"
            content="Clique aqui e edite este cliente"
            visible={false}
            overlayStyle={{ zIndex: 1051 }}
          >
            <a className="ant-dropdown-link" href="#">
              Ações <Icon type="down" />
            </a>
          </Popover>
        </Dropdown>
      </center>
    ),
  },
].filter(Boolean);
